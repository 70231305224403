export enum HttpStatusCode {
  OK = 200,
  Created = 201,
  BadRequest = 400,
  NotFound = 404,
  Unauthorized = 401
}

export enum DataEvent {
  Saved = 0,
  Deleted = 1
}

export enum Day {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export enum CatalogItemState {
  None = 0,
  New = 1,
  Discount = 2
}

export enum FacilityType {
  None = 0,
  AlcoholicDrinks = 1,
  LunchandDinner = 2,
  FreeWifi = 3,
  WheelchairAccessible = 4,
  ParkingAvailable = 5,
  VeganOptions = 6,
  Delivery = 7,
  CreditCards = 8,
  Pickup = 9
}
export enum OrderStatus {
  // submitted = 'submitted',
  awaitingvalidation = 'awaitingvalidation',
  // stockconfirmed = 'stockconfirmed',
  // paid = 'paid',
  // shipped = 'shipped',
  cancelled = 'cancelled',
  completed = 'completed',
  inprocess = 'inprocess'
}

export enum OrderStatusCommon {
  AwaitingValidation = 2,
  StockConfirmed = 3,
  Shipped = 5,
  Cancelled = 6,
  Completed = 7,
  InProcess = 8,
  Ready = 9,
  StartShip = 10
}

export enum ExecutorTypeCommon {
  Courier = 2,
  Person = 3,
  System = 5,
  Partner = 6,
  SalePortal = 7,
}

export enum ExecutorTypeDisplayProperty {
  courier = 'courier',
  person = 'person',
  system = 'system',
  partner = 'partner',
  salePortal = 'salePortal',
}

export enum PaymentMethod {
  cash = 'isCash',
  paid = 'Paid',
  online = 'isOnline',
  pos = 'posTerminal'
}

export enum CheckoutTypeName {
  Unknown = 'unknown',
  Portion = 'portion',
  Pickup = 'pickUp',
  Delivery = 'delivery',
  Reservation = 'reservation',
  WithoutMenu = 'withoutMenu',
  UniqueQR = 'uniqueQr',
  Kiosk = 'kiosk'
}

export enum DisplayType {
  Text = 0,
  Image = 1,
  Date = 2
}

export enum ActiveThemeType {
  Template_1 = 0,
  Template_2 = 1,
  Template_3 = 2,
  Template_4 = 3,
  Template_5 = 4,
  Template_6 = 5
}

export enum ThemePropertyType {
  Default = 0,
  Color = 1,
  Position = 2,
  FontSize = 3,
  FontWeight = 4,
}
export enum ECheckoutType {
  Unknown = 0,
  Portion = 1,
  Pickup = 2,
  Delivery = 3,
  Reservation = 4,
  UniqueQR = 5,
  WithoutMenu = 6,
  Kiosk = 7
}

export enum PictureSize {
  Small = 1,
  Medium = 2,
  Big = 3,
  Origin = 4
}
export enum NotificationType {
  OrderView = 0,
  DirectoryView = 1
}

export enum QrTemplateRenderMode {
  Image = 0,
  Canvas = 1
}

export enum QrTemplateMode {
  Plain = 0,
  Label = 1,
  Image = 2,
}

export enum DistanceType {
  None = 'None',
  Auto = 'Auto',
  Manual = 'Manual'
}
export enum CalculateDistanceType {
  Air = 'Air',
  Route = 'Route'
}

export enum Refund {
  None = 0,
  Full = 1,
  Partial = 2,
  Both = 3,
}

export enum Verification {
  Email = 'Email',
  Sms = 'Sms',
  Call = 'Call'
}

export enum ThemeView {
  BoxMenu = 'BoxMenu',
  ListMenu = 'ListMenu'
}

export enum ThemeColors {
  MainBackground = 'mainBackground',
  MainColorOne = 'mainColorOne',
  TitleColor = 'titleColor',
  MainColorTwo = 'mainColorTwo',
  ItemBorderColor = 'itemBorderColor',
  BorderColor = 'borderColor',
  ProductTextColor = 'productTextColor',
  ProductLightTextColor = 'productLightTextColor',
  IconColor = 'iconColor',
}
export enum EValueType {
  Text = 'Text',
  Number = 'Number',
  Range = 'Range',
}

export enum SalePortalGroupType {
  None = 'None',
  Filter = 'Filter',
  Value = 'Value',
  Select = 'Select',
  QrGroup = 'QrGroup'
}
export enum SalePortalGroupSubType {
  Restaurants = 'restaurants',
  Cuisines = 'cuisines',
  Locations = 'locations',
  Prices = 'prices',
  SeatingAreas = 'seatingareas',
  Tags = 'tags',
  Populars = 'populars',
  Discounts = 'discounts',
  Other = 'other'
}
export enum SalePortalGroupMode {
  Filter = 'Filter',
  Group = 'Group'
}
export enum CatalogGroupType {
  Default = 'Default',
  Filter = 'Filter',
}
export enum EmployeeType {
  Unknown = 'Unknown',
  Scanner = 'Scanner',
  OrderReceiver = 'OrderReceiver',
  Courier = 'Courier',
  Operator = 'Operator'
}


export enum VehicleType {
  Unknown = 0,
  Scooter = 1,
  Car = 2
}

export enum DragState {
  Moving = 0,
  Dropped = 1,
  Started = 2
}

export enum FeedbackStatus {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved'
}

export enum CouponValueType {
  Percent = 'Percent',
  FixedDiscount = 'FixedDiscount'
}

export enum CouponStatus {
  New = 'New',
  Pending = 'Pending',
  Active = 'Active',
  Blocked = 'Blocked',
  Expired = 'Expired'
}

export enum CouponUserType
{
    None = 'None',
    Partner = 'Partner',
    Employee = 'Employee',
    Customer = 'Customer',
}
export enum CouponType
{
    Main = 'Main',
    Subscription = 'Subscription',
}

export enum PartnerSubscriptionStatus {
  Active = 'Active',
  Expired = 'Expired',
  Cancelled = 'Cancelled'
}
export enum OrderItemStatus {
  Pending = 1,
  Reject = 2,
  Approved = 3,
  Cancelled = 4
}
export class SharedEnums {
  static getString(enums, keyString?: boolean): Array<{ key: any, value: any }> {
    let objKeys;
    if (!keyString) {
      objKeys = Object.keys(enums).map(x => Number.parseInt(x));
    } else {
      objKeys = Object.keys(enums).map(x => x.toString());
    }
    const keys: { key: any, value: any }[] = [];
    objKeys.forEach(x => {
      if (enums[x]) {
        keys.push({ key: x, value: enums[x] });
      }
    });
    return keys;
  }
}
