import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { StorageKeys } from '../shared/services/constant.service';
@Injectable()
export class StorageService {
  private storage: any;
  public refreshToken = false;
  constructor() {
    this.storage = localStorage;
  }

  public retrieve(key: string, withAuthPrefix = false): any {
    let storeKey = key;
    if (withAuthPrefix) {
      storeKey = this.getAuthPrefix() + '_' + key;
    }
    const item = this.storage.getItem(storeKey);
    if (item && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(storeKey));
    }
    return;
  }

  public store(key: string, value: any, useAuthPrefix = false) {
    let storeKey = key;
    if (useAuthPrefix) {
      storeKey = this.getAuthPrefix() + '_' + key;
    }
    this.storage.setItem(storeKey, JSON.stringify(value));
  }

  public remove(key: string, withAuthPrefix = false) {
    let storeKey = key;
    if (withAuthPrefix) {
      storeKey = this.getAuthPrefix() + '_' + key;
    }
    const item = this.storage.getItem(storeKey);
    if (item && item !== 'undefined') {
      this.storage.removeItem(storeKey);
    }
  }

  private getAuthPrefix() {
    const token = this.retrieve('access_token');
    if (!token) {
      return '';
    }
    const decoded = jwt_decode<any>(token);
    return decoded.sub;
  }

  public clearStorageKeys() {
    this.remove(StorageKeys.FEEDBACK_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.FEEDBACK_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.HOT_ACTIONS_HISTORY_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.HOT_ACTIONS_HISTORY_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.CATALOG_ITEM_GROUP_KEY, true);
    this.remove(StorageKeys.ORDERING_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.ORDERING_DATE_INTERVAL_KEY + '_end', true);
    this.remove('pos_' + StorageKeys.ORDERING_DATE_INTERVAL_KEY + '_start', true);
    this.remove('pos_' + StorageKeys.ORDERING_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.ORDERING_PRODUCT_REPORT_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.ORDERING_PRODUCT_REPORT_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.ORDERING_ACTIVE_CHECKOUT_DATE__INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.ORDERING_ACTIVE_CHECKOUT_DATE__INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.CLIENT_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.CLIENT_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.USERDATACHECKED_KEY, true);
    this.remove(StorageKeys.SCROLL_DATA_NAME, true);
    this.remove(StorageKeys.SMART_TRANSLATE_DATA_NAME, true);
    this.remove(StorageKeys.NOTIFICATION_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.NOTIFICATION_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.SALE_PORTAL_COMPANY_TYPE_KEY);
    this.remove(StorageKeys.FROM_SALE_PORTAL, true);
    this.remove(StorageKeys.SALE_PORTAL_SELECTED_PARTNER);
    this.remove(StorageKeys.SALE_PORTAL_SELECTED_PARTNER_FILTER);
    this.remove(StorageKeys.SALE_PORTAL_TRANSLATE_HOST);
    this.remove(StorageKeys.SALE_PORTAL_RESTAURANT_FILTER, true);
    this.remove(StorageKeys.SALE_PORTAL_RESTAURANT_DATE_INTERVAL_KEY + '_start', true);
    this.remove(StorageKeys.SALE_PORTAL_RESTAURANT_DATE_INTERVAL_KEY + '_end', true);
    this.remove(StorageKeys.SALE_PORTAL_RESTAURANT_DATE_INTERVAL_KEY + '_use', true);
    this.remove(StorageKeys.QR_URLTYPE, true);
  }
}
